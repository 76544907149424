import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"flex",attrs:{"fluid":""}},[_c(VRow,{staticClass:"mb-0",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"md":9,"sm":12}},[_c('div',{staticClass:"home px-5 pt-10"},[_c(VToolbar,{attrs:{"flat":"","color":"accent-4"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c(VTabs,{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTab,{attrs:{"to":{ name: 'TeamsPage' }}},[_vm._v(" "+_vm._s(_vm.$t('teams'))+" ")]),_c(VTab,{attrs:{"to":{ name: 'StaffPage' }}},[_vm._v(" "+_vm._s(_vm.$t('staff'))+" ")]),_c(VTab,{attrs:{"to":{ name: 'ClubPlayersPage' }}},[_vm._v(" "+_vm._s(_vm.$t('players'))+" ")])],1)]},proxy:true}])},[_c(VBtn,{attrs:{"icon":"","color":"light","to":{ name: 'Clubs' }}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(" mdi-chevron-left ")])],1),_c(VToolbarTitle,[_c('h1',[_vm._v(_vm._s(_vm.name))])]),_c(VSpacer)],1),_c('router-view',{attrs:{"club":_vm.$route.params.id}})],1)]),_c(VCol,{staticClass:"grey lighten-5 pt-10 px-5",attrs:{"md":3}})],1),_c(VSnackbar,{attrs:{"color":"light"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"dark","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }