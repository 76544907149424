
import Vue from 'vue'
// Club dashboard
export default Vue.extend({
  name: 'ClubPage',
  data () {
    return {
      activeTab: null,
      dialogStaff: false,
      validStaffForm: false,
      name: '',
      staffForm: {
        firstname: '',
        lastname: '',
        nameRules: [
          (v: any) => !!v || 'Name is required'
        ],
        email: '',
        emailRules: [
          (v: any) => !!v || 'E-mail is required',
          (v: any) => /.+@.+/.test(v) || 'E-mail must be valid'
        ]
      },
      snackbar: false,
      text: '',
      team: {}
    }
  },
  mounted () {
    this.$store.dispatch('ClubsModule/fetchById', this.$route.params.id).then(() => {
      this.name = this.$store.state.ClubsModule.data[this.$route.params.id].name
    })
  },
  destroyed () {
    this.$store.dispatch('ClubTeamsModule/closeDBChannel')
    this.$store.dispatch('PlayersModule/closeDBChannel')
  },
  methods: {
    openDialogStaff () {
      this.$data.dialogStaff = true
    },
    sendInvitation () {
      this.$data.text = 'Invitation sent!'
      this.$data.snackbar = true
    }
  }
})
